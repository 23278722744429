<script>
import simplebar from "simplebar-vue";
import {layoutComputed} from "@/state/helpers";

import MetisMenu from "metismenujs/dist/metismenujs";
import Multiselect from "vue-multiselect";

import {menu} from "./menu";
import {useUserStore} from "@/store/user";
import axios from "axios";
import {errorCatcher} from "@/helpers/error-catcher";
import CreateShopModal from "@/components/shop/create-shop-modal.vue";
import {userService} from "@/helpers/user.service";
import {computed} from "vue";
import Swal from "sweetalert2";
import {timeUtil} from "@/helpers/time-util";
import MultistoreModal from "./multistore-modal.vue";
import FlagButton from "@/components/FlagButton.vue";
import Notifications from "@/components/notification/notifications.vue";

export default {
  components: {
    Notifications, FlagButton,
    MultistoreModal,
    CreateShopModal,
    simplebar,
    Multiselect,
  },

  data() {
    return {
      sideBarOpen: false
    }
  },

  props: {
    isCondensed: {
      type: Boolean,
      default: false,
    },

    type: {
      type: String,
      required: true,
    },

    width: {
      type: String,
      required: true,
    },
  },

  computed: {
    Swal() {
      return Swal
    },
    menu() {
      return menu
    },

    timeUtil() {
      return timeUtil
    },

    getSelectedMarketId() {
      return this.$store ? this.$store.state.market.marketId : "";
    },

    updatedShopOptions() {
      const shops = this.$store.getters['shop/getShopsIds'];

      return [
        ...["new-shop"],
        ...shops,
      ];
    },

    getSelectedShopId() {
      return this.$store ? this.$store.state.shop.shopId : "";
    },

    ...layoutComputed,
  },

  mounted() {
    this.$root.$on("load-paired-shops", async () => await this.loadShops())
    this.updateSidebarLinks()
  },

  methods: {
    updateSidebarLinks() {
      // eslint-disable-next-line no-unused-vars
      var menuRef = new MetisMenu("#side-menu");
      var links = document.getElementsByClassName("side-nav-link-ref");
      var matchingMenuItem = null;

      for (var i = 0; i < links.length; i++) {
        if (window.location.pathname === links[i].pathname) {
          matchingMenuItem = links[i];
          break;
        }
      }

      if (matchingMenuItem) {
        matchingMenuItem.classList.add("active");
        var parent = matchingMenuItem.parentElement;

        /**
         * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
         * We should come up with non hard coded approach
         */

        if (parent) {
          parent.classList.add("mm-active");
          const parent2 = parent.parentElement.closest("ul");
          if (parent2 && parent2.id !== "side-menu") {
            parent2.classList.add("mm-show");

            const parent3 = parent2.parentElement;
            if (parent3) {
              parent3.classList.add("mm-active");
              var childAnchor = parent3.querySelector(".has-arrow");
              var childDropdown = parent3.querySelector(".has-dropdown");
              if (childAnchor) childAnchor.classList.add("mm-active");
              if (childDropdown) childDropdown.classList.add("mm-active");

              const parent4 = parent3.parentElement;
              if (parent4 && parent4.id !== "side-menu") {
                parent4.classList.add("mm-show");
                const parent5 = parent4.parentElement;
                if (parent5 && parent5.id !== "side-menu") {
                  parent5.classList.add("mm-active");
                  const childanchor = parent5.querySelector(".is-parent");
                  if (childanchor && parent5.id !== "side-menu") {
                    childanchor.classList.add("mm-active");
                  }
                }
              }
            }
          }
        }
      }
    },

    /**
     * Returns true or false if given menu item has child or not
     * @param item menuItem
     */
    hasItems(item) {
      return item.subItems !== undefined ? item.subItems.length > 0 : false;
    },

    onRoutechange() {
      setTimeout(() => {
        try {
          const currentPosition = document.getElementsByClassName("mm-active")[0]
              .offsetTop;

          if (currentPosition > 400) {
            this.$refs.currentMenu.SimpleBar.getScrollElement().scrollTop = currentPosition + 200;
          }
        } catch (error) {
          // ignored
        }
      }, 300);
    },

    async loadMarkets() {
      try {
        const {data} = await axios.get(`/market`, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        await this.$store.dispatch('market/setMarkets', data)
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadShops() {
      try {
        const {data} = await axios.get(`/shop`, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        if (!this.$store.getters["market/getMarketId"] && data.length > 0) {
          const shop = data[0]
          await this.$store.dispatch('market/setSelectedMarketId', shop.marketId)
          await this.$store.dispatch('shop/setSelectedShopId', shop.id)
        } else if (!this.$store.getters["shop/selectedShop"] && data.length > 0) {
          const shop = data.find(element => element.marketId === this.$store.getters["market/getMarketId"])
          await this.$store.dispatch('shop/setSelectedShopId', shop ? shop.id : "")
        }

        await this.$store.dispatch('shop/setShops', data)
        await this.loadPairedShops(data)
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadEngines() {
      const {data} = await axios.get(`/engine`, {
        data: {},
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      });

      await this.$store.dispatch("engine/setEngines", data)
    },

    async loadStripe() {
      await this.loadPublishableKey()
    },

    async loadPublishableKey() {
      try {
        const {data} = await axios.get(`/parameter/get-stripe-api-publishable-key`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {}
        });

        await this.$store.dispatch('stripe/setPublishableKey', data.value)
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadPairedShops(shops) {
      try {
        if (!shops || shops.length === 0) {
          return
        }

        const ids = [];
        for (let shop of shops) {
          ids.push(shop.id);
        }

        const json = JSON.stringify({
          "ids": ids
        });

        const {data} = await axios.post(`/shop/is-paired-shops`, json, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        await this.$store.dispatch('shop/setPairedShops', data.shops)
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadOperatorProvisionParameter() {
      try {
        const {data} = await axios.get(`/parameter/get-operator-profit-division`, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        await this.$store.dispatch('parameters/setOperatorProfitDivision', data.value)
        await this.loadEcatProfitDivisionParameter()
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadEcatProfitDivisionParameter() {
      try {
        const {data} = await axios.get(`/parameter/get-ecat-profit-division`, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        await this.$store.dispatch('parameters/setEcatProfitDivision', data.value)
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadAderloShopIdParameter() {
      try {
        const {data} = await axios.get(`/parameter/get-aderlo-shop-id`, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        await this.$store.dispatch('parameters/setAderloShopId', data.value)
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadAllegroSendMinPrice() {
      try {
        const {data} = await axios.get(`/parameter/get-allegro-send-min-price`, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        await this.$store.dispatch('parameters/setAllegroSendMinPrice', Number(data.value))
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    getShop(id) {
      const shops = this.$store.getters['shop/getShops']
      if (!shops) {
        return
      }

      for (const shop of shops) {
        if (shop.id === id) {
          return shop
        }
      }

      return {}
    },

    getMarket(id) {
      const markets = this.$store.getters['market/getMarkets']
      if (!markets) {
        return
      }

      for (const market of markets) {
        if (market.id === id) {
          return market
        }
      }

      return {}
    },

    changeSelectedMarketId(id) {
      this.$store.dispatch('market/setSelectedMarketId', id).then(() => {
        this.$store.dispatch('shop/setSelectedShopId', "").then(() => {
          Swal.fire("Sukces!", "Pomyślnie ustawiono market!", "success");
          setTimeout(() => this.$router.go(this.$router.currentRoute), 700)
        })
      })
    },

    async loadProductVariationByType(productType) {
      try {
        const {data} = await axios.get(`/marketplace/product/variation/list`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
          params: {
            "type": productType,
          }
        })

        await this.$store.dispatch("storelimits/setStoreLimits", data)
        await this.loadCurrentUserMultiStorePlan()
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadMultiStoreSubscription() {
      try {
        const { data } = await axios.get(`/stripe/subscription/order/${this.user.shopPlanMarketplaceOrderId}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {}
        });

        await this.$store.dispatch("storelimits/setCurrentMultiStoreSubscription", data)
      } catch (error) {
        console.log(error)
      }
    },

    async loadCurrentUserMultiStorePlan() {
      if (!this.user || !this.user.shopPlanMarketplaceOrderId) {
        return
      }

      await this.loadMultiStoreSubscription()

      try {
        const {data} = await axios.get(`/marketplace/order/product/${this.user.shopPlanMarketplaceOrderId}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {}
        })

        await this.loadCurrentMultiStoreVariation(data.marketplaceProductVariationId)
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadCurrentMultiStoreVariation(marketplaceProductVariationId) {
      try {
        const {data} = await axios.get(`/marketplace/product/variation/${marketplaceProductVariationId}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {}
        })

        await this.$store.dispatch("storelimits/setCurrentMultiStore", data)
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    changeSelectedShopId(id) {
      if (id === "new-shop") {
        this.$refs.createShopModal.openModal(null)
        return
      }

      this.$store.dispatch('shop/setSelectedShopId', id).then(() => {
        Swal.fire("Sukces!", "Pomyślnie ustawiono aktywny sklep!", "success");
        setTimeout(() => this.$router.go(this.$router.currentRoute), 700)
      })
    },

    getShopName(id) {
      if (id === "new-shop") {
        return "Utwórz nowy sklep"
      }

      const shop = this.getShop(id)
      return shop.name || ""
    },

    blurBackground() {
      const width = window.innerWidth < 992;
      const sidebarEnable = document.body.classList.contains("sidebar-enable");

      return width && sidebarEnable;
    },

    toggleMenu() {
      this.$parent.toggleMenu();
    },

    toggleTheme() {
      this.$store.dispatch('theme/switchTheme').then(() => this.$store.dispatch('theme/updateTheme'))
    },

    async loadAll() {
      await this.loadProductVariationByType("STORE_LIMIT");
      await this.loadStripe()
      await this.loadOperatorProvisionParameter()
      await this.loadAderloShopIdParameter()
      await this.loadAllegroSendMinPrice()
      await this.loadEngines()
      await this.loadMarkets()
      await this.loadShops()
    }
  },

  setup() {
    const userStore = useUserStore();
    return {
      userService,
      user: computed(() => userStore.getUser)
    }
  },

  async created() {
    await this.loadAll()
  },

  beforeDestroy() {
    this.$root.$off("load-paired-shops");
  }
};
</script>
<template>
  <!-- ========== Left Sidebar Start ========== -->
  <div>
    <multistore-modal ref="multiStoreRef"/>

    <div class="vertical-menu border-right">
      <div class="d-flex justify-content-center py-2 border-bottom border-top" style="padding: 0 10px">
        <div @click="$refs.multiStoreRef.openModal()" class="btn btn-primary py-2 font-size-14 text-center w-100">
          <i class="mdi mdi-shopping font-size-18 mr-2"></i>
          <span class="font-weight-medium font-size-14">Multistore {{
              $store.getters["storelimits/getCurrentMultiStore"] ? $store.getters["storelimits/getCurrentMultiStore"].title : 'Darmowy'
            }}</span>
        </div>
      </div>

      <simplebar class="bar py-2" ref="currentMenu" id="my-element">
        <!--- Sidemenu -->
        <div id="sidebar-menu">
          <!-- Left Menu Start -->
          <ul class="metismenu list-unstyled" id="side-menu">
            <template v-for="(item, index) in menu.getItems()">
              <li class="menu-title" v-if="item.isTitle" :key="index"> <!--  :key="item.id" -->
                {{ $t(item.label) }}
              </li>

              <!--end Layouts menu -->
              <li v-if="!item.isTitle && !item.isLayout" :key="index"> <!-- :key="item.id" -->
                <a
                    v-if="hasItems(item)"
                    href="javascript:void(0);"
                    class="is-parent"
                    :class="{
                  'has-arrow': !item.badge,
                  'has-dropdown': item.badge,
                }"
                >
                  <i :class="`bx ${item.icon ? item.icon : 'dripicons-dot'}`"></i>
                  <span>{{ $t(item.label) }}</span>
                  <span
                      :class="`badge badge-pill badge-${item.badge.variant} float-right`"
                      v-if="item.badge"
                  >{{ $t(item.badge.text) }}</span
                  >
                </a>

                <router-link
                    :to="item.link"
                    v-if="!hasItems(item)"
                    class="side-nav-link-ref"
                    :class="item.link === $route.path ? 'router-link-exact-active router-link-active' : ''"
                >
                  <i :class="`bx ${item.icon ? item.icon : 'dripicons-dot'}`"></i>
                  <span>{{ $t(item.label) }}</span>
                  <span
                      :class="`badge badge-pill badge-${item.badge.variant} float-right`"
                      v-if="item.badge"
                  >{{ $t(item.badge.text) }}</span
                  >
                </router-link>

                <ul v-if="hasItems(item)" class="sub-menu" aria-expanded="false">
                  <li v-for="(subitem, index) of item.subItems" :key="index">

                    <template v-if="subitem.link === 'coming-soon'">
                      <a @click="Swal.fire('Dostępne wkrótce')" class="side-nav-link-ref clickable-element" style="color: var(--primary);">
                        <i :class="`bx ${subitem.icon ? subitem.icon : 'dripicons-dot'}`" style="color: var(--primary);"></i>{{ $t(subitem.label) }}
                      </a>
                    </template>
                    <template v-else>
                      <router-link :to="subitem.link" v-if="!hasItems(subitem)" class="side-nav-link-ref" :class="subitem.link === $route.path ? 'router-link-exact-active router-link-active' : ''"><i
                          :class="`bx ${subitem.icon ? subitem.icon : 'dripicons-dot'}`"></i>{{ $t(subitem.label) }}
                      </router-link>
                    </template>

                    <a v-if="hasItems(subitem)" class="side-nav-link-a-ref has-arrow" href="#"><i
                        :class="`bx ${subitem.icon ? subitem.icon : 'dripicons-dot'}`"></i>{{ $t(subitem.label) }}</a>

                    <ul v-if="hasItems(subitem)" class="sub-menu mm-collapse" aria-expanded="false">
                      <li v-for="(subSubitem, index) of subitem.subItems" :key="index">
                        <router-link :to="subSubitem.link" class="side-nav-link-ref" :class="subSubitem.link === $route.path ? 'router-link-exact-active router-link-active' : ''">
                          <i :class="`bx ${subSubitem.icon ? subSubitem.icon : 'dripicons-dot'}`"></i>{{
                            $t(subSubitem.label)
                          }}
                        </router-link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </template>
          </ul>
        </div>
        <!-- Sidebar -->
      </simplebar>

      <div class="d-flex align-items-center flex-column border-top"
           style="padding-top: 10px !important; padding-bottom: 8px !important;">

        <div class="px-2 pb-1" v-if="$store.getters['market/getMarkets'] && $store.getters['market/getMarketsIds']">
          <multiselect
              class="market-selector"
              :value="getSelectedMarketId"
              @select="changeSelectedMarketId"
              :custom-label="value => $t(getMarket(value).i18nTranslation)"
              :options="$store.getters['market/getMarketsIds']"
              placeholder="Wybierz rynek"
              :show-labels="false">

            <template slot="singleLabel" slot-scope="{ option }">
              <img :src="getMarket(option).imageUrl" alt="Icon" style="max-width: 24px; max-height: 24px;"/>
              {{ $t(getMarket(option).i18nTranslation) }}
            </template>

            <template slot="option" slot-scope="{ option }">
              <img :src="getMarket(option).imageUrl" alt="Icon" style="max-width: 24px; max-height: 24px;"/>
              {{ $t(getMarket(option).i18nTranslation) }}
            </template>

          </multiselect>
        </div>

        <div class="px-2 pt-1" v-if="$store.getters['shop/getShops'] && $store.getters['shop/getShopsIds']">
          <multiselect
              class="shop-selector"
              :value="getSelectedShopId"
              @select="changeSelectedShopId"
              :custom-label="value => getShopName(value)"
              :options="updatedShopOptions"
              placeholder="Wybierz aktywny sklep"
              :show-labels="false"
          />
        </div>

      </div>

      <div class="d-inline-flex align-items-center justify-content-center w-100 border-top">
        <a href="https://discord.gg/ecat" target="_blank" class="btn btn-sm p-3 px-1 font-size-24 header-item">
          <i class="ri ri-discord-fill"></i>
        </a>

        <button @click="toggleTheme" type="button" class="btn btn-sm px-3 font-size-24 header-item">
          <i :class="$store.getters['theme/isThemeDark'] ? 'ri ri-sun-line' : 'ri ri-moon-line'"></i>
        </button>

        <FlagButton :drop-up="true" />

        <notifications/>
      </div>
    </div>

    <div id="closeSideBar" class="sidebar-close-btn d-none">
      <a @click="toggleMenu()">
        <i class="mdi mdi-close"></i>
      </a>
    </div>
    <!-- Left Sidebar End -->

    <div id="backdrop" class="" @click="toggleMenu()"/>

    <create-shop-modal ref="createShopModal"/>
  </div>

</template>

<style lang="scss" scoped>
.market-selector, .shop-selector {
  color: #9398AB;
  width: 230px !important;
}
</style>
