import {useUserStore} from "@/store/user";

const userStore = useUserStore()
const user = userStore.getUser

export const menu = {
    getItems,
};

import store from "@/state/store";

function getItems() {
    const menuItems = [];

    menuItems.push(
        {
            id: 1,
            label: 'menuitems.dashboard.text',
            icon: 'mdi mdi-home',
            link: '/dashboard/home'
        },
        {
            id: 2,
            label: 'menuitems.dashboard.list.news',
            icon: 'ri ri-newspaper-fill',
            link: '/dashboard/news'
        },
        {
            id: 3,
            label: 'menuitems.dashboard.list.instructions',
            icon: 'mdi mdi-compass',
            link: '/dashboard/instructions-categories'
        },
        {
            id: 4,
            label: 'menuitems.dashboard.list.e-wallet',
            icon: 'mdi mdi-wallet',
            link: '/dashboard/e-wallet'
        }
    );

    const allegroItemsList = []

    if (store.getters['role/hasPermission']('ALLEGRO')) {
        allegroItemsList.push({
            id: 5,
            label: 'Allegro',
            icon: 'fas fa-store-alt fs-1',
            link: '/dashboard/user/allegro'
        })
    }

    const shopCategories = []
    if (!store.getters['shop/selectedShopIsVirtual']) {
        shopCategories.push({
            id: 3,
            label: 'menuitems.store.list.categories',
            link: '/dashboard/shop/categories'
        })
    }

    menuItems.push(
        {
            id: 5,
            label: 'menuitems.store.text',
            icon: 'ri-shopping-cart-2-fill',
            subItems: [
                {
                    id: 1,
                    label: 'menuitems.store.list.manage',
                    link: '/dashboard/user/shops'
                },
                {
                    id: 2,
                    label: 'menuitems.store.list.products',
                    link: '/dashboard/products'
                },
                ...shopCategories,
                {
                    id: 4,
                    label: 'menuitems.store.list.orders',
                    link: '/dashboard/user/orders'
                },
                {
                    label: 'products-catalog.title',
                    link: '/dashboard/products-catalog'
                },
                ...allegroItemsList
                // {
                //     id: 6,
                //     label: 'menuitems.store.list.ownproducts',
                //     link: '/dashboard/shop/operator-products'
                // },
                // {
                //     id: 7,
                //     label: 'Allegro',
                //     subItems: [
                //         {
                //             id: 8,
                //             label: 'menuitems.store.list.allegro.auction.queue',
                //             link: '/dashboard/allegro/auction-queue'
                //         },
                //         {
                //             id: 9,
                //             label: 'menuitems.store.list.allegro.auction.list',
                //             link: '/dashboard/allegro/auction-list'
                //         },
                //         {
                //             id: 10,
                //             label: 'menuitems.store.list.allegro.auction.price',
                //             link: '/dashboard/allegro/prices-list'
                //         },
                //         {
                //             id: 11,
                //             label: 'menuitems.store.list.allegro.auction.after-sale-service',
                //             link: '/dashboard/allegro/after-sale-service'
                //         },
                //     ]
                // },
            ]
        });

    menuItems.push(
        {
            id: 6,
            label: 'marketplace.title',
            icon: 'fas fa-store-alt fs-1',
            subItems: [
                {
                    id: 1,
                    label: 'marketplace.title',
                    link: '/dashboard/marketplace'
                },
                {
                    id: 2,
                    label: 'marketplace-user-orders.title',
                    link: '/dashboard/marketplace/user/orders'
                },
                {
                    id: 3,
                    label: 'marketplace-user-subscriptions.title',
                    link: '/dashboard/marketplace/user/subscriptions'
                }
            ]
        }
    )

    menuItems.push(
        {
            id: 8,
            label: 'menuitems.wholesaler.text',
            icon: 'ri-building-4-fill',
            subItems: [
                {
                    id: 1,
                    label: 'menuitems.wholesaler.list.management',
                    link: '/dashboard/wholesaler/management'
                },
                {
                    id: 2,
                    label: 'menuitems.wholesaler.list.available',
                    link: '/dashboard/wholesaler/available'
                }
            ]
        }
    )

    menuItems.push(
        {
            id: 9,
            label: 'menuitems.affiliate-program.title',
            icon: 'mdi mdi-trophy-award',
            subItems: [
                {
                    id: 1,
                    label: 'menuitems.affiliate-program.list.account-list',
                    icon: 'mdi mdi-account-multiple',
                    link: '/dashboard/partner-program/account-list'
                },
                {
                    id: 2,
                    label: 'menuitems.wholesaler.text',
                    link: '/dashboard/partner-program/wholesalers-list'
                }
            ]
        },
        {
            label: "eCommerce Points Pool",
            link: "/dashboard/ecommerce-points-pool"
        },
        {
            label: "ECAT Rewards Program",
            link: "/dashboard/ecat-rewards-program"
        },
        {
            label: "Aderlo Cashback",
            link: "/dashboard/aderlo-cashback"
        }
    )

    const adminTechnicalDepartmentList = []

    if (store.getters['role/hasPermission']('TECHNICAL_DEPARTMENT')) {
        adminTechnicalDepartmentList.push(
            {
                id: 17,
                label: 'manage-roles.title',
                link: '/dashboard/admin/manage-roles'
            }
        )

        adminTechnicalDepartmentList.push({
            id: 6,
            label: 'menuitems.admin.list.users',
            icon: 'mdi mdi-account-supervisor',
            link: '/dashboard/admin/users'
        })

        adminTechnicalDepartmentList.push({
            id: 6,
            label: 'admin.parameters.title',
            link: '/dashboard/admin/parameters'
        })
    }

    const adminDataPageOrShopEditorList = []

    if (store.getters['role/hasPermission']('DATA_PAGE') || store.getters['role/hasPermission']('SHOP_EDITOR')) {
        adminDataPageOrShopEditorList.push({
            id: 5,
            label: 'menuitems.admin.list.shops',
            icon: 'ri-shopping-cart-2-line',
            link: '/dashboard/admin/shops'
        })
    }

    let grzegorzList = []
    if (store.getters["grequiredemails/getRequiredEmails"].includes(user.email)) {
        grzegorzList.push({
            label: 'Grzegorz',
            link: '/dashboard/admin/aderlo-cashback-panel'
        })
    }

    if (store.getters['role/hasPermission']('TECHNICAL_DEPARTMENT')) {
        menuItems.push(
            {
                id: 10,
                label: 'menuitems.admin.text',
                icon: 'ri-key-2-line',
                subItems: [
                    ...grzegorzList,
                    {
                        label: "Aderlo",
                        subItems: [
                            {
                                label: "Produkty",
                                link: "/dashboard/admin/aderlo/products"
                            },
                            {
                                label: "Kategorie",
                                link: "/dashboard/admin/aderlo/categories"
                            }
                        ]
                    },
                    {
                        id: 3,
                        label: 'menuitems.admin.list.warehouses.title',
                        subItems: [
                            {
                                label: 'warehouses.title',
                                link: '/dashboard/admin/warehouses'
                            },
                            {
                                label: 'menuitems.admin.list.parser.list.parsers',
                                icon: 'ri-pencil-ruler-2-line',
                                link: '/dashboard/admin/parser/parsers'
                            },
                            {
                                label: 'menuitems.admin.list.parser.list.banned-phrase',
                                icon: 'mdi mdi-block-helper',
                                link: '/dashboard/admin/parser/banned-phrase'
                            },
                            {
                                label: 'notification-for-new-tags.title',
                                icon: 'mdi mdi-information',
                                link: '/dashboard/admin/parser/notifications'
                            },
                            {
                                label: 'warehouse-status.title',
                                link: '/dashboard/admin/warehouse-status'
                            },
                            {
                                label: 'menuitems.admin.list.wholesaler.list.registration-forms',
                                link: '/dashboard/admin/wholesaler/registration-forms'
                            },
                            {
                                label: 'industries.title',
                                icon: 'ri-building-2-line',
                                link: '/dashboard/admin/industries'
                            },
                            {
                                label: "Domyślne cenniki",
                                link: "/dashboard/admin/global-shipping-rates"
                            }
                        ]
                    },
                    {
                        label: 'menuitems.admin.list.reports.title',
                        subItems: [
                            {
                                id: 5,
                                label: 'core.monthly-order-reports.title',
                                link: '/dashboard/core/monthly-order-reports'
                            }
                        ]
                    },
                    {
                        id: 4,
                        label: 'core.depots.title',
                        link: '/dashboard/core/depots'
                    },
                    {
                        id: 7,
                        label: 'core.withdrawals.title',
                        link: '/dashboard/core/withdrawals'
                    },
                    {
                        id: 1,
                        label: 'menuitems.admin.list.logs',
                        icon: 'mdi mdi-source-repository',
                        link: '/dashboard/admin/logs'
                    },
                    {
                        id: 2,
                        label: 'menuitems.admin.list.news',
                        icon: 'mdi mdi-newspaper',
                        link: '/dashboard/admin/news'
                    },
                    // {
                    //     id: 3,
                    //     label: 'menuitems.admin.list.instructions',
                    //     icon: 'mdi mdi-compass',
                    //     link: '/dashboard/admin/instructions'
                    // },
                    {
                        id: 4,
                        label: 'menuitems.admin.list.short-codes',
                        icon: 'mdi mdi-code-array',
                        link: '/dashboard/admin/shortcodes'
                    },
                    {
                        id: 7,
                        label: 'menuitems.admin.list.email-templates',
                        icon: 'ri-mail-line',
                        link: '/dashboard/admin/email-templates'
                    },
                    {
                        id: 9,
                        label: 'menuitems.admin.list.investors.title',
                        subItems: [
                            {
                                id: 1,
                                label: 'menuitems.admin.list.investors.list.list',
                                icon: 'mdi mdi-account-supervisor',
                                link: '/dashboard/admin/investors/list'
                            },
                        ]
                    },
                    {
                        id: 10,
                        label: 'menuitems.admin.list.categories',
                        icon: 'mdi mdi-file-tree',
                        link: '/dashboard/admin/categories'
                    },
                    {
                        id: 12,
                        label: 'pickup-points.title',
                        icon: 'ri-building-2-line',
                        link: '/dashboard/admin/pickup-points'
                    },
                    {
                        id: 14,
                        label: 'Allegro',
                        subItems: [
                            {
                                id: 1,
                                label: 'allegro-history-auctions.title',
                                link: '/dashboard/admin/allegro-history-auctions'
                            },
                            {
                                id: 2,
                                label: 'admin.allegro-accounts.title',
                                link: '/dashboard/admin/allegro-accounts'
                            },
                            {
                                id: 2,
                                label: 'Statystyki',
                                link: '/dashboard/admin/allegro/statistics'
                            }
                        ]
                    },
                    {
                        id: 15,
                        label: 'registration-access.title',
                        link: '/dashboard/admin/registration-access'
                    },
                    {
                        id: 16,
                        label: 'Marketplace',
                        subItems: [
                            {
                                id: 1,
                                label: 'admin.marketplace.products.title',
                                link: '/admin/marketplace/products'
                            },
                            {
                                id: 2,
                                label: 'admin.marketplace.orders.title',
                                link: '/dashboard/admin/marketplace/orders'
                            }
                        ]
                    },
                    ...adminTechnicalDepartmentList,
                    ...adminDataPageOrShopEditorList,
                    {
                        label: 'admin.carriers.title',
                        link: '/dashboard/admin/carriers'
                    },
                    {
                        label: 'admin.markets.title',
                        link: '/dashboard/admin/markets'
                    },
                    {
                        label: 'admin.languages.title',
                        link: '/dashboard/admin/languages'
                    }
/*                    {
                        id: 18,
                        label: 'Allegro VPN',
                        icon: 'ri-shopping-cart-2-fill',
                        link: '/dashboard/admin/allegrovpn'
                    }*/
                ]
            }
        );
    }

    let corePermissionCoreAdminProductsList = []
    if (store.getters['role/hasPermission']('CORE_ADMIN_PRODUCTS')) {
        corePermissionCoreAdminProductsList = [
            {
                id: 2,
                label: 'menuitems.store.list.products',
                link: '/dashboard/admin/products'
            }
        ]
    }

    let firstCorePermissionTechnicalDepartmentList = []
    let secondCorePermissionTechnicalDepartmentList = []
    if (store.getters['role/hasPermission']('TECHNICAL_DEPARTMENT')) {
        firstCorePermissionTechnicalDepartmentList = [
            {
                id: 1,
                label: 'menuitems.core.list.orders',
                icon: 'mdi mdi-server-network',
                link: '/dashboard/core/orders'
            },
        ]
        secondCorePermissionTechnicalDepartmentList = [
            {
                id: 2,
                label: 'core.products.variations.title',
                link: '/dashboard/core/products/variations'
            },
            {
                id: 6,
                label: 'core.package-senders.title',
                link: '/dashboard/core/package-senders'
            },
            {
                id: 6,
                label: 'core.customers.title',
                link: '/dashboard/core/customers'
            }
        ]
    }

    if (store.getters['role/hasPermission']('TECHNICAL_DEPARTMENT') || store.getters['role/hasPermission']('CORE_ADMIN_PRODUCTS')) {
        menuItems.push(
            {
                id: 11,
                label: 'menuitems.core.title',
                icon: 'ri ri-shield-keyhole-fill',
                subItems: [
                    ...firstCorePermissionTechnicalDepartmentList,
                    ...corePermissionCoreAdminProductsList,
                    ...secondCorePermissionTechnicalDepartmentList
                ]
            }
        );
    }

    return menuItems
}