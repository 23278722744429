<script>
import FlagButton from '@/components/FlagButton.vue';
import {useUserStore} from "@/store/user";

import axios from "axios";

import Swal from "sweetalert2";
import {errorCatcher} from "@/helpers/error-catcher";
import Notifications from "@/components/notification/notifications.vue";
import {logoHelper} from "@/helpers/logo-helper";
import {userService} from "@/helpers/user.service";
import {computed} from "vue";
import {isNumeric} from "echarts/lib/util/number";

export default {
  components: {
    Notifications,
    FlagButton,
  },

  data() {
    return {
      orderNumberId: ""
    }
  },

  methods: {
    toggleMenu() {
      this.$parent.toggleMenu();
    },

    searchOrder() {
      if (!this.orderNumberId || !isNumeric(this.orderNumberId)) {
        Swal.fire("", "Wprowadź numer zamówienia!", "error");
        return
      }

      axios.get(`/shop-order/fetch/by-number-id`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        data: {},
        params: {
          numberId: Number(this.orderNumberId),
        }
      }).then(({ data }) => {
        window.location = `/dashboard/core/order/details?shopOrderId=${data.id}`
      }).catch((error) => {
        errorCatcher.catchErrors(error)
      });
    },

    toggleTheme() {
      this.$store.dispatch('theme/switchTheme').then(() => this.$store.dispatch('theme/updateTheme'))
    },

    async endSimulation() {
      await this.backSession(this.$store.getters["simulatesession/getOldSession"])
      await this.deleteSession(this.$store.getters["simulatesession/getNewSession"])
      await this.$store.dispatch("simulatesession/clearSessions")
      await this.$store.dispatch("shop/setSelectedShopId", "")
      await this.$store.dispatch("market/setSelectedMarketId", "")

      await this.$router.push('/dashboard/home').catch((error) => {
        this.$router.go(this.$router.currentRoute)

        if (error.name !== 'NavigationDuplicated' && !error.message.includes('Avoided redundant navigation to current location')) {
          console.log(error)
        }
      })
    },

    async deleteSession(session) {
      try {
        await axios.delete(`/user/simulation/${session.sessionId}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {}
        });
      } catch (error) {
        errorCatcher.catchErrors(error)
        throw error
      }
    },

    async backSession(session) {
      try {
        const json = JSON.stringify(session);
        await axios.post(`/user/simulation/change-session`, json, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    }
  },

  computed: {
    logoHelper() {
      return logoHelper
    },
  },

  setup() {
    const userStore = useUserStore();
    return {
      userService,
      user: computed(() => userStore.getUser)
    }
  },
};
</script>

<template>
  <div>
    <header id="page-topbar" style="z-index: 5;">
      <div class="navbar-header">
        <div class="d-flex">
          <div class="navbar-brand-box border-right">
            <router-link to="/dashboard/home" href="#" class="logo">
              <span class="logo-sm">
                <img :src="logoHelper.logo()" alt="logo" height="44"/>
              </span>
              <span class="logo-lg">
                <img :src="logoHelper.logo()" alt="logo" height="44"/>
              </span>
            </router-link>
          </div>

          <button
              @click="toggleMenu"
              type="button"
              class="btn btn-sm px-3 font-size-24 header-item waves-effect menu"
              id="vertical-menu-btn">
            <i class="ri-menu-2-line align-middle"></i>
          </button>

          <template v-if="$store.getters['role/hasPermission']('TECHNICAL_DEPARTMENT')">
            <div class="app-search pl-3">
              <div class="position-relative">
                <input type="text" class="form-control" placeholder="Numer zamówienia..." v-model="orderNumberId" @keyup.enter="searchOrder" />
                <span class="ri-search-line"></span>
                <div class="search-button">
                  <b-button variant="outline-primary" class="rounded-pill" @click="searchOrder">Szukaj</b-button>
                </div>
              </div>
            </div>
          </template>
        </div>

        <div class="center-logo-header">
          <router-link to="/dashboard/home" href="#" class="logo">
              <span class="logo-sm">
                <img :src="logoHelper.logo()" alt="logo" height="44"/>
              </span>
          </router-link>
        </div>

        <div class="d-inline-flex">
          <div class="buttons-area">
            <b-button @click="endSimulation" variant="danger" class="btn-sm" v-if="$store.getters['simulatesession/hasSession']">
              Zakończ symulację
            </b-button>

            <a href="https://discord.gg/ecat" target="_blank" class="btn btn-sm p-3 px-1 font-size-24 header-item">
              <i class="ri ri-discord-fill"></i>
            </a>

            <button @click="toggleTheme" type="button" class="btn btn-sm px-3 font-size-24 header-item">
              <i :class="$store.getters['theme/isThemeDark'] ? 'ri ri-sun-line' : 'ri ri-moon-line'"></i>
            </button>

            <FlagButton/>

            <notifications/>
          </div>
          <template v-if="user">
            <b-dropdown
                right
                variant="black"
                toggle-class="header-item"
                class="d-inline-block user-dropdown">
              <template v-slot:button-content>
                <img
                    class="rounded-circle header-profile-user"
                    :src="user.profilePictureUrl"
                    alt="Header Avatar"
                />
                <span class="d-none d-xl-inline-block ml-2 mr-1">{{ user.firstName }} {{ user.lastName }}</span>
                <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
              </template>

              <!-- item-->
              <router-link to="/dashboard/user/profile" class="dropdown-item" href="#">
                <i class="ri-user-line align-middle mr-1"></i>
                {{ $t('navbar.dropdown.profile') }}
              </router-link>

              <router-link to="/dashboard/user/security" class="dropdown-item">
                <i class="ri-lock-line align-middle mr-1"></i>
                {{ $t('navbar.dropdown.security') }}
              </router-link>

              <router-link to="/dashboard/user/business" class="dropdown-item" href="#">
                <i class="ri-briefcase-5-line align-middle mr-1"></i>
                {{ $t('navbar.dropdown.business') }}
              </router-link>

              <div class="dropdown-divider"></div>
              <a class="dropdown-item text-danger" @click="userService.logout" href="#">
                <i class="ri-shut-down-line align-middle mr-1 text-danger"></i>
                {{ $t('navbar.dropdown.logout') }}
              </a>
            </b-dropdown>
          </template>
        </div>
      </div>
    </header>
  </div>
</template>

<style lang="scss" scoped>
.notify-item {
  .active {
    color: #16181b;
    background-color: #f8f9fa;
  }
}
</style>