<script>
import router from "@/router";
import { layoutComputed } from "@/state/helpers";

import Topbar from "@/components/topbar";
import SideBar from "@/components/side-bar";
import axios from "axios";
import {errorCatcher} from "@/helpers/error-catcher";
// import Rightsidebar from "@/components/right-sidebar";
// import Footer from "@/components/footer";

export default {
  components: { Topbar, SideBar, /*Rightsidebar, Footer*/ },
  data() {
    return {
      isMenuCondensed: false,
      informationBars: []
    };
  },

  async created() {
    // document.body.removeAttribute("data-layout", "horizontal");
    // document.body.removeAttribute("data-topbar", "dark");
    // document.body.setAttribute("theme-mode", "dark");

    await this.loadInformationBars()
  },

  // created: () => {
  //   document.body.removeAttribute("data-layout", "horizontal");
  //   document.body.removeAttribute("data-topbar", "dark");
  //   document.body.setAttribute("data-sidebar", "dark");

    // this.loadInformationBars()
  // },

  computed: {
    ...layoutComputed,
  },

  methods: {
    async loadInformationBars() {
      try {
        const {data} = await axios.get(`/information-bar/${this.$store.getters["translation/getCurrentLocale"]}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {}
        });

        this.informationBars = data
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    toggleMenu() {
      document.body.classList.toggle("sidebar-enable");
      document.getElementById("backdrop").classList.toggle("custom-backdrop")
      document.getElementById("closeSideBar").classList.toggle("d-none")

      router.afterEach(() => {
        document.body.classList.remove("sidebar-enable");
        document.getElementById("backdrop").classList.remove("custom-backdrop")
        document.getElementById("closeSideBar").classList.remove("d-block")
      });
      this.isMenuCondensed = !this.isMenuCondensed;
    },

    toggleRightSidebar() {
      document.body.classList.toggle("right-bar-enabled");
    },

    hideRightSidebar() {
      document.body.classList.remove("right-bar-enabled");
    },
  },

  mounted() {
    this.$root.$on('ecat-reload-information-bar', () => this.loadInformationBars())

    document.body.classList.remove("auth-body-bg");
    if (this.loader === true) {
      document.getElementById("preloader").style.display = "block";
      document.getElementById("status").style.display = "block";

      setTimeout(function () {
        document.getElementById("preloader").style.display = "none";
        document.getElementById("status").style.display = "none";
      }, 2500);
    } else {
      document.getElementById("preloader").style.display = "none";
      document.getElementById("status").style.display = "none";
    }
  },

  beforeDestroy() {
    this.$root.$off("ecat-reload-information-bar")
  }
};
</script>

<template>
  <div>
    <div id="preloader">
      <div id="status">
        <div class="spinner">
          <i class="ri-loader-line spin-icon"></i>
        </div>
      </div>
    </div>

    <!-- Begin page -->
    <div id="layout-wrapper">
      <Topbar />
      <SideBar
        :is-condensed="isMenuCondensed"
        :type="leftSidebarType"
        :width="layoutWidth"
      />
      <!-- ============================================================== -->
      <!-- Start right Content here -->
      <!-- ============================================================== -->
      <div class="main-content">
        <div class="page-content">
          <div class="container-fluid">
            <div v-for="(informationBar, index) in informationBars" :key="index" id="ecat-moving-text-topbar" style="z-index: 5; border-radius: 0.25rem" :style="`background-color: ${informationBar.backgroundColorHex}`">
              <div class="col-12">
                <div class="py-2">
                    <div class="overflow-hidden ecat-moving-text-container">
                      <div class="ecat-moving-text" :style="`color: ${informationBar.textColorHex}`">
                        {{ informationBar.message }}
                      </div>
                    </div>
                </div>
              </div>
            </div>

            <slot />
          </div>
        </div>
<!--        <Footer />-->
      </div>
<!--      <Rightsidebar />-->
    </div>
  </div>
</template>